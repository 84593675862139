












/**
 * @description Common field input
 */
import Vue from "vue";
import rules from "./rules";

export default Vue.extend({
  name: "field-input",
  props: {
    label: String,
    value: String,
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mValue: "",
      rules: [rules.required],
    };
  },
  watch: {
    value(val: string) {
      this.mValue = val;
    },
  },
});
