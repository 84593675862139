















/**
 * @description Field for user data like phone
 */
import Vue from "vue";
import rules from "@/components/account/rules";

export default Vue.extend({
  name: "field-phone",
  props: {
    label: String,
    value: String,
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rules,
    };
  },
});
